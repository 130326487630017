<template>
  <div>
    <SearchHeader title='Signatures' icon='cubes' prompt='Search for a signature by providing a name, description, or other identifying details.' @change='search'/>
    <SearchResults v-if='searchText || signatures.length > 0' :data='signatures && Array.isArray(signatures) ? signatures : []' className='signature' :fields='tableFields' :actions='tableActions ? tableActions : []' :dataActions='tableDataActions  ? tableDataActions : []' @clickAction='handleAction'/>
    <b-row v-if='canCreateSignature()'>
      <b-col>
        <b-button size='xs' variant='primary' @click='addSignature()'>Add Signature</b-button>
      </b-col>
    </b-row>
    <DeleteRecordModal ref='deleteModal' className='signature' :id='deleteId' @deleted='search(searchText)'/>
    <TabbedModal v-if='signatures[selectedSignatureIndex]' id='signature_modal' :visible='showSignatureModal' @change='handleHideModal' title='Manage Signature' @input='setSelectedTab'>
      <Tab title='Details'>
        <Form @submit="handleSubmit" id='signature-form' :saveVisibleOverride='canUpdateSignature()'>
          <SignatureEdit ref='signature' @UpdateSignature='UpdateSignature' :signature="signatures[selectedSignatureIndex]" @entrypoint_wait_show='$emit("entrypoint_wait_show", $event)' @entrypoint_wait_hide='$emit("entrypoint_wait_hide")'/>
        </Form>
      </Tab>
    </TabbedModal>
  </div>
</template>

<script>
  import abilityTester from '@/config/abilityTester';
  import DeleteRecordModal from '@/components/modal/DeleteRecord';
  import Form from '@/components/form/Form';
  import { mapMutations } from 'vuex';
  import {ADD_ERROR} from '@/store/MutationTypes';
  import rest from '@/common/tools/Rest';
  import TabbedModal from '@/components/modal/TabbedModal';
  import Tab from '@/components/elements/tabs/Tab';
  import SearchHeader from '@/components/search/Header';
  import SearchResults from '@/components/search/Results';
  import SignatureEdit from '@/components/signature/Edit';

  export default {
    name: 'SignatureList',
    components: {
      DeleteRecordModal,
      Form,
      TabbedModal,
      Tab,
      SearchHeader,
      SearchResults,
      SignatureEdit,
    },
    computed: {
      selectedSignatureId(){
        return this.signatures[this.selectedSignatureIndex] && this.signatures[this.selectedSignatureIndex].id ? this.signatures[this.selectedSignatureIndex].id : '';
      },
      selectedSignatureIsNew() {
        return  this.selectedSignatureId ? false : true
      },
      showSignatureModal() {
        return this.signatures[this.selectedSignatureIndex] ? true : false;
      },
      tableDataActions() {
        var resp = [];
        for(var i in this.signatures){
          var actions = [];
          actions.push('view');
          if(this.canDeleteSignature(this.signatures[i].id)){
            actions.push('delete');
          }
          resp.push(actions);
        }
        return resp;
      }
    },
    data() {
      return {
        deleteId: '',
        searchText: '',
        selectedSignatureIndex: null,
        selectedTab: null,
        tableActions: {
          delete: {title: 'Delete', icon: 'times'},
          view: {title: 'View', icon: 'edit'}
        },
        tableFields: [
          {key: 'description', displayStatus: true},
          {key: 'Action', class: 'text-center'}
        ],
        signatures: []
      }
    },
    methods: {
      ...mapMutations('messages', {
        error: ADD_ERROR
      }),
      addSignature(){
        var newSignature = {
          description: '',
          path: '',
          username: '',
          password: '',
          url: '',
          port: '',
          Products: []
        };
        var i = this.signatures.push(newSignature) - 1;
        this.displaySignatureModal(i);
      },
      canCreateSignature(){
        return abilityTester.can('create', 'signature');
      },
      canDeleteSignature(id){
        return abilityTester.can('delete', 'signature', {signature_id: id});
      },
      canUpdateSignature(){
        return this.$refs.signature ? this.$refs.signature.canUpdateSignature() : SignatureEdit.methods.canUpdate(this.signatures[this.selectedSignatureIndex]);
      },
      displayDeleteModal(id){
        this.deleteId = id;
        this.$refs.deleteModal.show();
      },
      displaySignatureModal(index){
        this.selectedSignatureIndex = index;
      },
      handleAction(action, item, index){
        switch(action){
          case 'delete':
            this.displayDeleteModal(item.id);
            break;
          case 'view':
            this.displaySignatureModal(index);
            break;
        }

      },
      handleHideModal(show){
        if(!show){
          this.selectedSignatureIndex = null;
          this.setSelectedTab(0);
        }
      },
      handleSubmit(){
        this.$refs.signature.save();
      },
      search(text){
        if(text.length === 0){
          this.searchText = '';
          this.signatures = [];
          return;
        }
        this.selectedSignatureIndex = null;
        this.searchText = text;
        var args = {
          'loading': 'Products',
        }
        this.$emit('entrypoint_wait_show', 'Searching...');
        rest.get('signature/search/' + text, 'signature', args).then(signatures => {
          this.signatures = Array.isArray(signatures) ? signatures : [];
        }).catch(e => {
          this.error(rest.getErrorMessage(e));
        }).finally(() => {
            this.$emit('entrypoint_wait_hide');
          })
      },
      UpdateSignature(signature) {
        this.signatures[this.selectedSignatureIndex] = signature;
        this.$refs.signature.$forceUpdate();
      },
      setSelectedTab(value){
        this.selectedTab = value;
      },
    }
  }
</script>

<style scoped>
</style>
